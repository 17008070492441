<template>
  <div>
    <div class="crafting-info-row">
      <p>
        {{ $t('craftingQueue') }}:
        <vue-countdown
          v-slot="{ days, hours, minutes, seconds }"
          :time="queueTimeInitial"
          @progress="
            ({ totalMilliseconds }) => {
              queueTime = runningRecipes && runningRecipes.length > 0 ? totalMilliseconds : 0;
            }
          "
        >
          {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
          {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
          {{
            BigNumber(minutes).isGreaterThan(0) ||
            (BigNumber(minutes).isLessThanOrEqualTo(0) && BigNumber(seconds).isLessThanOrEqualTo(0))
              ? `${formatToZeroPrefixedNumber(minutes)}m`
              : ''
          }}
          {{
            BigNumber(hours).isGreaterThan(0) || (BigNumber(minutes).isZero() && BigNumber(seconds).isZero())
              ? ''
              : `${formatToZeroPrefixedNumber(seconds)}s`
          }}
        </vue-countdown>
      </p>

      <p>
        {{ $t('recipeCraftingTime') }}:
        <vue-countdown
          v-if="recipeToCraft"
          v-slot="{ days, hours, minutes }"
          :time="recipeToCraft.duration * 1000"
          :auto-start="false"
          :emit-events="false"
        >
          {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
          {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
          {{ `${formatToZeroPrefixedNumber(minutes)}m` }}
        </vue-countdown>
      </p>
    </div>

    <div v-if="!isRecipeLoading && recipeToCraft" class="crafting-info-duration">
      <span v-if="recipeToCraft.duration > 0" class="crafting-info-row-time">
        {{ $t('willBeReadyIn') }}:
        <vue-countdown
          v-if="recipeToCraft"
          v-slot="{ days, hours, minutes, seconds }"
          :emit-events="false"
          :time="readyTime"
          :auto-start="false"
        >
          <span class="crafting-info-row-time-ready">
            {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
            {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
            {{ `${formatToZeroPrefixedNumber(minutes)}m` }}
            {{
              BigNumber(hours).isGreaterThan(0) ||
              (BigNumber(hours).isZero() && BigNumber(minutes).isZero()) ||
              BigNumber(seconds).isZero()
                ? ''
                : `${formatToZeroPrefixedNumber(seconds)}s`
            }}
          </span>
        </vue-countdown>
      </span>

      <span v-else class="crafting-info-row-time"> {{ $t('craftingTime') }}: {{ $t('instant') }}</span>
    </div>

    <div v-if="!isRecipeLoading" class="crafting-recipe">
      <ul class="crafting-materials">
        <CraftingMaterial
          v-for="(item, i) of recipeToCraft?.ingredients"
          :key="i"
          :ingredient="item"
          :produce-amount="produceAmount"
          :placeholder-width-string="materialWidthPlaceholderString"
        />
      </ul>
      <div class="crafting-arrow">→</div>
      <div v-for="finalProduct of recipeToCraft?.products" :key="finalProduct.productId" class="crafting-crafted">
        <img v-if="isNft" class="random-nft" src="/img/icons/random-nft.svg" alt="nft-random" />
        <img
          class="crafted-item-img"
          :src="tokenMetaData(blockchain.contracts, finalProduct.tokenAddress).media"
          :alt="tokenMetaData(blockchain.contracts, finalProduct.tokenAddress).label"
        />
        <p v-if="isNft" class="valuable">
          <span v-if="computedRecipeId === '9'">{{ $t('coreRare') }}</span>
          <span v-else-if="computedRecipeId === '7'">{{ $t('coreUncommon') }}</span>
          <span v-else>{{ $t('coreCommon') }}</span>
        </p>
        <p v-if="!isNft">
          <span v-if="new BigNumber(finalProduct.quantityMin).eq(finalProduct.quantityMax)">
            {{ new BigNumber(finalProduct.quantityMax).multipliedBy(produceAmount || 0) }}
          </span>
          <span v-else>
            {{ new BigNumber(finalProduct.quantityMin).decimalPlaces(1).multipliedBy(produceAmount || 0) }}-{{
              new BigNumber(finalProduct.quantityMax).decimalPlaces(1).multipliedBy(produceAmount || 0)
            }}
          </span>
        </p>
        <p v-else>{{ finalProduct.nftValueMin }} - {{ finalProduct.nftValueMax }}</p>
      </div>
    </div>

    <div class="crafting-buttons">
      <div class="crafting-buttons-wrapper">
        <el-input-number
          v-model="produceAmount"
          :step="1"
          :step-strictly="true"
          :validate-event="false"
          :min="1"
          :disabled="!maxAmountAvailableForCraft"
          :max="maxAmountAvailableForCraft || 1"
          :value-on-clear="maxAmountAvailableForCraft || 1"
        />
        <button
          class="btn-outline btn-max"
          type="button"
          :disabled="isItemCraftingInjected || maxAmountAvailableForCraft <= 0"
          @click="setMaxProduceItem"
        >
          {{ $t('craftingMax') }} ({{ maxAmountAvailableForCraft }})
        </button>
      </div>

      <button
        class="btn-primary produce"
        :disabled="
          !address ||
          isItemCraftingInjected ||
          maxAmountAvailableForCraft <= 0 ||
          produceAmount > maxAmountAvailableForCraft ||
          !isEnoughTokens
        "
        @click="handleToggleConfirmationModalInjected && handleToggleConfirmationModalInjected()"
      >
        {{ $t('craftingProduce') }}
        <span v-if="confirmationNumberRefInjected && isItemCraftingInjected"
          >{{ confirmationNumberRefInjected }} / {{ blockchain.minConfirmationsCount }}</span
        >
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { CraftingMaterial } from '#components';
import { BigNumber } from 'bignumber.js';
import { tokenMetaData } from '~/utils';
import { useFetch } from '#app';
import useEnvs from '~/composables/useEnvs';

import type {
  // ActiveUsersModalProps,
  BuildingProps,
  RecipeProps,
  RecipeProduct,
  RunningRecipeProps
} from '~/types/crafting';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

const props = defineProps<{
  recipeId: string;
}>();

const computedRecipeId = computed(() => props.recipeId);
const building = inject<BuildingProps>('building');
const confirmationNumberRefInjected = inject<number>('confirmationNumberRef');
const isItemCraftingInjected = inject<boolean>('isItemCrafting');

const handleToggleConfirmationModalInjected = inject<() => void>('handleToggleConfirmationModal');
const setProductMultiplierInjected = inject<(multiplier: number) => void>('setProductMultiplier');
const userActivityItems = inject<any>('userActivity');

const { apiUrl, blockchain } = useEnvs();
const { availableTokens } = useTokensReader();
const { address } = useWeb3ModalAccount();
const store = useMainStore();

const { data: runningRecipes, refresh: refreshRunningRecipes } = await useFetch<RunningRecipeProps[]>(
  () => `/crafting/recipes/in-queue`,
  {
    baseURL: apiUrl,
    query: {
      recipeId: computedRecipeId,
      buildingHash: building?.buildingHash
    },
    immediate: true
  }
);

const lastRunningRecipeEndTime = computed(() =>
  Math.max(...(runningRecipes.value?.map((rec) => new Date(rec.craftEndTime).getTime()) || []))
);

const queueTimeInitial = computed(() =>
  BigNumber(lastRunningRecipeEndTime.value - Date.now()).isPositive() ? lastRunningRecipeEndTime.value - Date.now() : 0
);
const queueTime = ref(
  BigNumber(lastRunningRecipeEndTime.value - Date.now()).isPositive() ? lastRunningRecipeEndTime.value - Date.now() : 0
);
const readyTime = computed(() => queueTime.value + (recipeToCraft.value?.duration || 0) * produceAmount.value * 1000);

store.$onAction(async ({ name }) => {
  if (name === 'updateVersion') {
    setTimeout(() => refreshRunningRecipes(), 2000);
  }
});

const { data: recipes } = await useFetch<RecipeProps[]>(`/crafting/buildings/${building?.buildingHash}/recipes`, {
  baseURL: apiUrl
});

const { data: recipeToCraft, pending: isRecipeLoading } = await useFetch<RecipeProps>(
  () => `/crafting/recipes/${props.recipeId}`,
  {
    baseURL: apiUrl
  }
);

const isNft = computed(() => {
  if (!recipeToCraft) return false;

  return (
    recipeToCraft.value &&
    recipeToCraft.value.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});
const currentTime = Date.now();

const maxAmountAvailableForCraft = ref(recipeToCraft.value?.maxQueue || 0);
const isEnoughTokens = ref(true);
const produceAmount = ref(1);
const materialWidthPlaceholderString = ref('');
const getRecipeTime = (time: string) => new Date(time).getTime();

const tokensForClaimRef = computed(
  () =>
    userActivityItems.flat().find((item: any) => {
      return (
        item.buildingHash === building?.buildingHash &&
        new Date(runningRecipe.value?.craftStartTime || 0)?.getTime() === item.date * 1000
      );
    })?.available || 0
);

const runningRecipe = computed(() => {
  return runningRecipes.value?.find((recipe) => {
    return (
      recipe.userAddress === address.value &&
      getRecipeTime(recipe.craftStartTime) <= currentTime &&
      currentTime <= getRecipeTime(recipe.craftEndTime)
    );
  });
});

watch(
  [
    recipeToCraft,
    address,
    tokensForClaimRef,
    () => recipeToCraft.value?.recipeId,
    () => runningRecipes.value?.length,
    () => isItemCraftingInjected
  ],
  async ([newRecipeToCraft, newAddress]) => {
    const maxQueue = recipes.value?.find((recipe) => recipe.recipeId === recipeToCraft.value?.recipeId)?.maxQueue || 1;

    if (!newAddress || !newRecipeToCraft) {
      maxAmountAvailableForCraft.value = 0;
      return;
    }

    const balanceAbilityToProduce = newRecipeToCraft.ingredients.map(async (ingredient) => {
      const ingredientBalance = !newAddress ? 0 : await availableTokens(newAddress, ingredient.tokenAddress);

      const placeholderString = `${BigNumber(ingredientBalance).toFormat(0)} / ${BigNumber(ingredient.quantity)}`;
      if (placeholderString.length > materialWidthPlaceholderString.value.length) {
        materialWidthPlaceholderString.value = placeholderString;
      }

      return new BigNumber(ingredientBalance).dividedBy(new BigNumber(ingredient.quantity)).toNumber();
    });
    const promises = await Promise.all(balanceAbilityToProduce);
    if (promises.some((tokenProduceAbility) => tokenProduceAbility < 1)) isEnoughTokens.value = false;

    const minRecipesCount = Math.floor(Math.min(...promises));
    const maxAvailable = minRecipesCount >= maxQueue ? maxQueue : minRecipesCount;

    if (newRecipeToCraft && newRecipeToCraft?.duration > 0 && minRecipesCount >= maxQueue) {
      maxAmountAvailableForCraft.value =
        (maxAvailable || 0) -
        (runningRecipes.value?.reduce((acc, currentValue) => acc + currentValue.multiplyModifier, 0) || 0) +
        tokensForClaimRef.value;
    } else {
      maxAmountAvailableForCraft.value = (maxAvailable || 0) + tokensForClaimRef.value;
    }
  },
  { immediate: true }
);

watch(produceAmount, (newValue) => {
  setProductMultiplierInjected && setProductMultiplierInjected(newValue);
});

watch(
  () => props.recipeId,
  async () => {
    await refreshRunningRecipes();
    produceAmount.value = 1;
    queueTime.value = BigNumber(lastRunningRecipeEndTime.value - Date.now()).isPositive()
      ? lastRunningRecipeEndTime.value - Date.now()
      : 0;
  }
);

const setMaxProduceItem = () => {
  produceAmount.value = maxAmountAvailableForCraft.value;
};
</script>

<style lang="scss">
.crafting-crafted {
  position: relative;

  p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 1rem;
    position: relative;
    top: -10px;
    color: #fff;
  }

  .valuable {
    color: #ffe604;
    font-size: 1.2rem;
  }
}

.crafting-buttons {
  &-wrapper {
    display: flex;
    gap: 10px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
  .amount {
    height: 50px;
  }

  .produce {
    padding: 4px 40px 0px 40px;
  }
}

.random-nft {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.crafted-item-img {
  width: 75px;
  height: 75px;
  margin-top: 2px;
  margin-left: 5px;
}

.btn-max {
  border-radius: 12px;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 50px;
}
</style>
