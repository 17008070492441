<template>
  <header>
    <div class="main-header-container" :class="{ 'show-mobile': showMobileMenu }">
      <div class="header-wrapper">
        <NuxtLink to="/">
          <div class="header-logo-link w-nav-brand">
            <img src="/img/logo.png" alt="" class="header-logo pd-logo" />
          </div>
        </NuxtLink>

        <ul class="header-ul header-links">
          <li>
            <NuxtLink to="/story"> {{ $t('menuStory') }} </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/marketplace"> {{ $t('menuMarketplace') }} </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/dashboard"> {{ $t('menuDashboard') }} </NuxtLink>
          </li>
          <li v-if="address">
            <NuxtLink class="address-link" to="/referral">{{ $t('referralTitle') }}</NuxtLink>
          </li>
        </ul>
        <ul class="header-ul login-wrapper">
          <li>
            <span v-if="loading" class="custom-btn-as-link btn-primary">{{ $t('coreLoading') }} ...</span>
            <template v-else-if="!address">
              <div class="custom-btn-as-link btn-primary btn-login-wrapper" @click="handleLogin">
                <span>{{ $t('coreLogin') }}</span>
                <div class="login-icon"></div>
              </div>
            </template>
            <template v-else>
              <div class="login-menu-wrapper menu-hide-on-mobile">
                <div class="address logged-in-wrapper w-inline-block">
                  <span class="user-icon" /><span>{{ formatAddress(address) }}</span>
                  <img
                    class="line-square-icon button-arrow logged-in"
                    src="/public/img/icons/simple_arrow.svg"
                    loading="lazy"
                    alt=""
                  />
                </div>
                <div class="login-menu-dropdown">
                  <div class="card user-popup">
                    <div class="dropdown-wrapper">
                      <div class="dropdown-item">
                        <a :href="link" target="_blank" class="address-link">
                          <img
                            class="menu-icon"
                            src="/public/img/icons/public_address.svg"
                            loading="lazy"
                            alt=""
                            width="19"
                          />
                          {{ $t('headerPublicAddress') }}
                        </a>
                      </div>

                      <div class="dropdown-item">
                        <img class="menu-icon" src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
                        <div class="">
                          {{ format(balance) }}
                        </div>
                      </div>

                      <div class="dropdown-item">
                        <div class="custom-btn logout-btn" @click="disconnectHandler">
                          <div class="logout-icon"></div>
                          <span>{{ $t('coreLogout') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="language-menu-wrapper menu-hide-on-mobile">
              <div class="address logged-in-wrapper language-wrapper w-inline-block">
                <span class="language-icon" />
                <span class="lang-letters"> {{ langAbbreviation }} </span>
              </div>
              <div class="login-menu-dropdown lang-menu-dropdown">
                <div class="card user-popup lang-popup">
                  <div class="dropdown-wrapper">
                    <div v-for="item in localeList" :key="item.code" class="dropdown-item dropdown-lang-item">
                      <div class="address-link lang-link" @click="switchLocalePathHandler(item.code)">
                        <span :class="{ active: item.code.toUpperCase() === langAbbreviation }">{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div
          class="hamburger-menu-wrapper lang-menu-wrapper"
          style="-webkit-user-select: text"
          aria-label="menu"
          role="button"
          tabindex="0"
          aria-controls="lang-overlay"
          aria-haspopup="menu"
          aria-expanded="false"
          @click="
            showLangMenu = !showLangMenu;
            showMobileMenu = false;
          "
        >
          <div class="address logged-in-wrapper language-wrapper language-wrapper-mobile w-inline-block">
            <span class="language-icon" /><span> {{ langAbbreviation }} </span>
          </div>
        </div>

        <div
          class="hamburger-menu-wrapper w-nav-button"
          style="-webkit-user-select: text"
          aria-label="menu"
          role="button"
          tabindex="0"
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="false"
          @click="
            showMobileMenu = !showMobileMenu;
            showLangMenu = false;
          "
        >
          <div class="hamburger-menu-bar top" />
          <div class="hamburger-menu-bar bottom" />
        </div>
      </div>
    </div>

    <div
      id="w-nav-overlay-0"
      class="w-nav-overlay"
      :class="{ 'show-mobile': showMobileMenu }"
      @click="showMobileMenu = false"
    >
      <div class="header-nav-menu-wrapper">
        <ul class="header-ul">
          <li>
            <NuxtLink to="/story"> {{ $t('menuStory') }} </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/marketplace"> {{ $t('menuMarketplace') }} </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/dashboard"> {{ $t('menuDashboard') }} </NuxtLink>
          </li>
          <li v-if="address">
            <NuxtLink class="address-link" to="/referral">{{ $t('referralTitle') }}</NuxtLink>
          </li>
          <template v-if="address">
            <li class="mobile-links first">
              <span class="user-icon" /><span>{{ formatAddress(address) }}</span>
            </li>
            <li class="mobile-links">
              <a :href="link" target="_blank">
                <img class="menu-icon" src="/public/img/icons/public_address.svg" loading="lazy" alt="" width="19" />
                {{ $t('headerPublicAddress') }}
              </a>
            </li>
            <li class="mobile-links">
              <div class="flex balance">
                <img class="menu-icon" src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
                {{ format(balance) }}
              </div>
            </li>

            <li class="mobile-links" @click="disconnectHandler">
              <a href="#" class="custom-btn logout-btn log-out">
                <img class="logout-arrow" src="/public/img/icons/logout_pink.svg" loading="lazy" alt="" width="24" />
                <span>{{ $t('coreLogout') }}</span>
              </a>
            </li>
          </template>

          <template v-else>
            <li class="mobile-links">
              <button class="btn-primary small header-btn-hidde-on-d no-wrap w-button" @click="handleLogin">
                <div class="login-mobile-wrapper">
                  <span>{{ $t('coreLogin') }}</span>
                  <img
                    class="line-square-icon button-arrow logged-in"
                    src="/public/img/icons/login-arrow.svg"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </button>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div id="lang-overlay" class="w-nav-overlay" :class="{ 'show-mobile': showLangMenu }">
      <div class="header-nav-menu-wrapper">
        <ul class="header-ul">
          <li v-for="item in localeList" :key="item.code" class="lang-li" @click="switchLocalePathHandler(item.code)">
            <span :class="{ active: item.code.toUpperCase() === langAbbreviation }">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BigNumber } from 'bignumber.js';
import { useMainStore } from '~/stores/mainStore';
import { NuxtLink } from '#components';
import useEnvs from '~/composables/useEnvs';
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useSwitchNetwork,
  useWalletInfo
} from '@web3modal/ethers/vue';
import { useDevice, useTokensReader } from '#imports';
import { mapState, mapActions } from 'pinia';
import { formatAddress, resetHashHistory } from '~/utils';
import { useI18n, useSwitchLocalePath } from '#imports';

export default defineComponent({
  components: {
    NuxtLink
  },
  setup() {
    const { switchNetwork } = useSwitchNetwork();
    const { open } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { blockchain, apiUrl } = useEnvs();
    const { isMobile } = useDevice();
    const { availableTokens } = useTokensReader();
    const { walletInfo } = useWalletInfo();
    const { t, locale, locales, setLocale } = useI18n();
    const switchLocalePath = useSwitchLocalePath();

    return {
      blockchain,
      apiUrl,
      open,
      disconnect,
      address,
      chainId,
      isConnected,
      walletProvider,
      isMobile,
      switchNetwork,
      availableTokens,
      walletInfo,
      t,
      locale,
      locales,
      setLocale,
      switchLocalePath
    };
  },
  data(): {
    loading: boolean;
    balance: string;
    showMobileMenu: boolean;
    showLangMenu: boolean;
  } {
    return {
      loading: true,
      balance: '',
      showMobileMenu: false,
      showLangMenu: false
    };
  },
  computed: {
    ...mapState(useMainStore, ['currentToken']),
    currentLocale() {
      const currentLocale = this.localeList.find((l: any) => l.code === this.locale);
      return currentLocale ? currentLocale.name : '';
    },
    localeList() {
      return this.locales;
    },
    link(): string {
      return this.blockchain.blockScanUrl + '/address/' + this.address;
    },
    walletProviderInstance() {
      return this.walletProvider;
    },
    langAbbreviation() {
      return this.locale.toUpperCase();
    }
  },
  watch: {
    address: {
      async handler() {
        await this.updateBalance();
        resetHashHistory();
      },
      immediate: true
    },
    walletProvider: {
      async handler(newValue) {
        if (this.walletProvider) {
          const userId = this.address;
          const walletId = this.walletInfo?.uuid ?? this.walletInfo?.name;
          const walletType = this.walletInfo?.name;

          useTrackEvent('connect_wallet', {
            walletId,
            walletType,
            userId
          });
        }

        if (!this.loading && newValue && !this.isMobile) {
          await newValue.request({
            method: 'wallet_requestPermissions',
            params: [{ eth_accounts: {} }]
          });
        }
      }
    }
  },
  methods: {
    ...mapActions(useMainStore, ['setUserData']),
    formatAddress,
    switchLocalePathHandler(code: string) {
      this.showLangMenu = false;
      return this.setLocale(code);
    },
    async handleLogin() {
      try {
        await this.open();

        useTrackEvent('login');
      } catch (error) {
        console.error('error on login', error);
      }
    },
    async disconnectHandler() {
      this.setUserData(null);
      resetHashHistory();
      await this.disconnect();
    },
    async onSuccess() {
      if (this.address !== null) {
        await this.updateBalance();
      }
    },
    async updateBalance(): Promise<void> {
      this.balance = '';
      if (!this.address) {
        this.loading = false;
        return;
      }
      this.loading = true;

      try {
        const balance = await this.availableTokens(this.address, this.blockchain.contracts.USDT);
        this.balance = Number(balance).toFixed(2);
      } finally {
        this.loading = false;
      }
    },
    format(value: string | number) {
      if (!value || value === '...') {
        return value;
      }
      return new BigNumber(value).toFormat(2);
    },
    pageName(name: string): string {
      switch (name) {
        case 'index':
          return 'Main';
        case 'story':
          return 'Story';
        case 'statistic':
          return 'Statistic';
        case 'leaderboard':
          return 'Leaderboard';
        default:
          return name;
      }
    }
  }
});
</script>

<style scoped lang="scss">
.w-nav-overlay {
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
}

.header-nav-menu-wrapper {
  background-color: #052430;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 25%);
  border-bottom: 1px solid #2f2f2f;
  padding: 8px 24px 32px;

  .header-ul {
    margin-top: 30px;

    .lang-li:not(:last-child) {
      padding-bottom: 30px;
    }

    .lang-li:hover,
    .active {
      color: white;
    }
  }

  .header-ul > li > a {
    margin-left: 12px;
  }

  display: none;

  @media screen and (max-width: 1200px) {
    display: block;
  }

  @media screen and (max-width: 479px) {
    .header-ul {
      flex-wrap: wrap;
    }

    .header-ul > li {
      width: 100%;
    }

    .header-ul > li > a {
      margin: 4px 0;
      width: 100%;
      padding: 12px 20px;
      font-size: 14px;
    }
  }
}

[data-nav-menu-open] {
  text-align: center;
  min-width: 200px;
  background: #c8c8c8;
  background-color: rgb(200, 200, 200);
  background-image: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: visible;
  display: block !important;
}

.show-mobile.w-nav-overlay {
  display: block;
}

.hamburger-menu-bar.top {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: all ease 200ms;
}

.hamburger-menu-bar.bottom {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: all ease 200ms;
}

.show-mobile {
  .hamburger-menu-bar.top {
    transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(135deg) skew(0deg);
  }
  .hamburger-menu-bar.bottom {
    transform: translate3d(0px, -7px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg);
  }
}

.main-header-container {
  max-width: 1340px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}

.w-nav-button {
  float: right;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 18px;
  font-size: 24px;
  display: none;
  position: relative;
}

.hamburger-menu-bar {
  width: 52px;
  height: 4px;
  background-color: #052430;
  border-radius: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.hamburger-menu-wrapper {
  padding: 0;
  transition: transform 0.3s;
}

.hamburger-menu-wrapper:hover {
  transform: scale(1.1);
}

.lang-menu-wrapper {
  display: block;
}

@media screen and (min-width: 479px) {
  .lang-menu-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .w-nav-button {
    display: block;
  }

  .hamburger-menu-bar {
    width: 32px;
    height: 3px;
    background-color: #fff;
  }

  .hamburger-menu-wrapper {
    margin-left: 24px;
  }
}

@media screen and (max-width: 767px) {
  .hamburger-menu-bar {
    width: 26px;
  }
}

.mobile-header {
  display: none;
  font-family: 'Grenze', sans-serif;
  font-weight: 800;
  font-size: 32px;
}

.down-arrow {
  background: url('~/public/img/Chevron.png');
  width: 20px;
  display: inline-flex;
  background-size: contain;
  height: 11px;
  margin-left: 12px;
}

.dropdown {
  box-sizing: border-box;
}

.dropdown-item {
  width: 100%;
  align-items: center;
  padding: 8px 24px;
  display: flex;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
      color: --main-text-color;
    }
  }
}

.dropdown-lang-item {
  padding: 8px 8px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.logout-btn {
  color: #ffbec2;
  cursor: pointer;

  .logout-icon {
    width: 16px;
    height: 15px;
    background: url('/public/img/icons/logout_pink.svg') left center no-repeat;
    margin-right: 11px;
  }

  &:hover {
    color: var(--canceled-color);

    .logout-icon {
      background: url('/public/img/icons/logout_red.svg') left center no-repeat;
    }
  }
}
.dropdown-item a {
  align-items: center;
  display: flex;
}

.header-ul > li {
  position: relative;
  font-size: 20px;
  white-space: nowrap;
}
.header-ul > li:hover .dropdown {
  display: block;
}

.custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.btn-login-wrapper {
  display: flex;
  align-items: center;
  grid-column-gap: 7px;

  &:hover {
    .login-icon {
      background-image: url('~/public/img/icons/login-arrow-hover.svg');
    }
  }
}

.custom-btn-as-link {
  margin-left: 32px;
  cursor: pointer;
  padding: 14px 24px;

  .login-icon {
    background-image: url('~/public/img/icons/login-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    width: 15px;
    transition: background-image 0.3s ease;
  }
}

.language-icon {
  background: url('~/public/img/icons/planet.svg') center center no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
  display: inline-flex;
  margin-right: 8px;
}

.lang-letters {
  padding-top: 2px;
}

.user-icon {
  background: url('~/public/img/icons/pirate.svg') center center no-repeat;
  background-size: contain;
  height: 24px;
  width: 24px;
  display: inline-flex;
  margin-right: 8px;
}

.btns-wrapper {
  display: flex;
  justify-content: center;
}

.exit-button {
  float: right;
  color: var(--main-text-color);
  text-decoration: none;
  padding: 12px 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms ease all;
  background: transparent;
  border-radius: 38px;
  border: 1px solid var(--dark-4);
}

header {
  height: 130px;
  background-color: var(--blue-1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  box-sizing: border-box;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-ul {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  margin-left: auto;
}

.header-ul > li > a {
  color: var(--main-text-color);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms ease all;
  font-family: 'Eczar', sans-serif;

  outline-offset: 0px;
  text-align: center;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 48px;
  outline: 1px #c49a4e;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.125em;
  transition:
    border-color 0.3s,
    transform 0.3s,
    color 0.3s;
  transform: translate(0);
  margin-left: 32px;

  &:hover {
    color: #c49a4e;
    border-color: #ddcead;
    transform: scale3d(0.96, 0.96, 1.01);
  }
}

.header-ul > li > a.router-link-active {
  color: #88e1ff;
}

.header-ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.address {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 32px;
}

.link-icon {
  width: 20px;
  height: 20px;
  display: inline-flex;
  background: url('~/public/img/icons/link.png') center center no-repeat;
  background-size: contain;
}

.header-wrapper {
  position: relative;
  border-bottom: 1px solid #17353f;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  width: 100%;
}

.header-logo-link {
  width: 100%;
  max-width: 283px;
  flex: 1;
  margin-right: 15px;
  padding-left: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.w-nav-brand {
  float: left;
  color: #333;
  text-decoration: none;
  position: relative;
}

.header-logo.pd-logo {
  width: auto;
  height: 50px;
}

.dropdown-wrapper {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .mobile-header {
    display: block;
  }

  .address {
    text-align: center;
  }

  .dropdown {
    .dropdown-wrapper {
      border: none;
    }

    .dropdown-item {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
}

.login-wrapper {
  margin-left: 0;
}

@media screen and (max-width: 1200px) {
  .header-links {
    display: none;
  }

  .login-wrapper {
    margin-left: auto;
  }
}

li.mobile-links {
  display: none;
  margin: 4px 0;

  .login-mobile-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logout-arrow {
    width: 15px;
    margin-right: 6px;
  }
}

li.mobile-links.first {
  margin-top: 12px;
  color: var(--main-text-color) !important;
}

@media screen and (max-width: 479px) {
  .login-wrapper {
    display: none;
  }
  li.mobile-links {
    display: flex;
    font-size: 14px;
    font-family: Eczar, sans-serif;
    font-weight: 500;
    line-height: 100%;

    a {
      border: none;
      color: var(--second-text-color);

      &:hover {
        color: var(--main-text-color);
      }
    }

    a.log-out {
      display: flex;
      align-items: center;
      color: #ffbec2;
      &:hover {
        color: var(--canceled-color);
      }
    }

    .flex:hover {
      color: var(--main-text-color);
    }
  }
  .header-logo.pd-logo {
    height: 32px;
  }

  .header-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.logged-in-wrapper {
  color: rgba(255, 255, 255, 0.5);
  transform-style: preserve-3d;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(59, 114, 133, 0.3));
  border: 2px solid #133442;
  border-radius: 48px;
  align-items: center;
  margin-left: 24px;
  padding: 12px 24px;
  font-family: Eczar, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
  transition:
    background-position 0.3s,
    background-color 0.3s,
    transform 0.3s,
    border 0.3s,
    color 0.3s;
  display: flex;
  transform: translate(0);
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--main-text-color);
    background-image: radial-gradient(circle farthest-side at 50% 160%, rgba(140, 253, 159, 0.4), rgba(0, 0, 0, 0)),
      linear-gradient(rgba(0, 0, 0, 0.8), rgba(59, 114, 133, 0.3));
    border-color: #3b7285;
    transform: scale3d(0.96, 0.96, 1.01);
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
}

.language-wrapper {
  background-image: unset;
  color: #fff;
  position: relative;

  &-mobile {
    border: unset;
  }

  &:hover {
    background-image: unset;
  }
}

.login-menu-wrapper,
.language-menu-wrapper {
  position: relative;
}

.login-menu-dropdown {
  opacity: 0;
  position: absolute;
  top: auto;
  bottom: 0%;
  transition: 600ms ease all;
  visibility: hidden;
  left: 50%;
  transform: translateX(-34%);
  width: 100%;
  z-index: 9999;
}

.lang-menu-dropdown {
  transform: translateX(-46%);
}

.card.user-popup {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(2, 27, 38, 1);
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: Eczar, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  display: flex;
  position: absolute;
  top: 1px;
  overflow: hidden;
  box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.4);
  z-index: 9999;
  min-width: 190px;
}

.lang-popup {
  min-width: 150px !important;
}

.login-menu-dropdown:hover,
.logged-in-wrapper:hover ~ .login-menu-dropdown {
  opacity: 1;
  visibility: visible;
}

.card.user-popup {
  font-family: Eczar, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.menu-icon {
  margin-right: 8px;
}

.link-icon,
.line-square-icon.menu-icon {
  margin-right: 8px;
  font-size: 20px;
}

.balance:hover {
  color: var(--main-text-color);
}

.line-square-icon.button-arrow.logged-in {
  color: #fff;
  font-size: 20px;
  line-height: 100%;
  padding-left: 13px;
  width: 25px;
  margin-left: -6px;
}

.address-link {
  color: var(--second-text-color);
  &:hover {
    color: var(--main-text-color);
  }
}

.flex {
  display: flex;
  align-items: center;
}

.line-square-icon.menu-icon.wallet-icon {
  color: #def2e6;
}

.lang-link {
  cursor: pointer;

  .active {
    color: white;
  }
}

.mobile-language-menu-wrapper {
  display: none;
}

.mobile-language-menu-wrapper.show-mobile {
  display: block;
}
.language-link {
  cursor: pointer;
}
</style>
