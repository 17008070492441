<template>
  <div>
    <el-dialog
      v-if="isShowReferralPopup"
      v-model="isShowReferralPopup"
      :title="$t('registerUserWelcomeToReferralProgram')"
      :append-to-body="true"
      modal-class="modalCls"
      :width="$device.isMobile ? '80%' : '50%'"
      @closed="closeReferral"
    >
      <p>{{ $t('registerUserNowYouHaveTheUniqueOpportunity') }}</p>
      <p>
        {{ $t('registerUserGetYourPersonalLink') }}
        <br />
        <span>{{ $route.query?.ref && `${$t('registerUserYourReferrersId')}: ${$route.query?.ref}` }}</span>
        <br />
        <span>{{ $t('registerUserJoinUsAndTurnYourRecommendations') }}</span>
      </p>

      <div class="btns">
        <button
          :loading="joinLoading"
          class="btn-primary participate"
          size="large"
          :disabled="joinLoading"
          @click="registerUserWithReferral(Number($route.query?.ref) || 0)"
        >
          <span>
            {{ !address ? $t('registerUserUseWalletToParticipate') : $t('registerUserParticipate') }}
            <span
              class="line-rounded-icon link-icon-right"
              style="
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg);
                transform-style: preserve-3d;
              "
              ></span
            >
          </span>
        </button>
      </div>
    </el-dialog>
    <el-dialog
      v-if="isShowReferrerError"
      v-model="isShowReferrerError"
      :title="$t('registerUserErrorInReferrerId')"
      :append-to-body="true"
      modal-class="modalCls"
      :width="$device.isMobile ? '80%' : '50%'"
    >
      <p>
        {{ $t('registerUserWeAreSorryButTheReferrerId') }}
        <span>{{ $route.query?.ref && `"${$route.query?.ref}" ` }}</span>
        {{ $t('registerUserInTheReferralLinkIsntValid') }}
      </p>
    </el-dialog>
    <el-dialog
      v-if="showCancelPopup"
      v-model="showCancelPopup"
      class="popup-canceled buy-popup"
      :fullscreen="$device.isMobile"
      :title="$t('referralPopupCanceledTitle')"
      :append-to-body="true"
    >
      <p>{{ $t(errorMessage) }}</p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="showCancelPopup = false">OK</button>
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
import { useRoute, useRouter } from '#app';
import { ElNotification, stringifyError, useDevice, useI18n, useMainStore } from '#imports';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { ZeroAddress } from 'ethers';
import { onMounted, ref, watch } from 'vue';
import PearlApiService from '~/composables/PearlApiService';
import useEnvs from '~/composables/useEnvs';
import type { IUserModel, IUserSubscribers } from '~/types/apiService';
import { ACTION_REJECTED, apiUrls } from '~/utils/constants';

const { t } = useI18n();

const { apiUrl } = useEnvs();
const { address, isConnected } = useWeb3ModalAccount();
const store = useMainStore();
const { open } = useWeb3Modal();
const { registerInReferral } = useStakingMethods();
const { blockchain } = useEnvs();

const route = useRoute();
const router = useRouter();
const { isMobile } = useDevice();
const isShowReferralPopup = defineModel<boolean>({ default: false });
const isShowReferrerError = ref(false);
const showCancelPopup = ref(false);
const errorMessage = ref('');
const joinLoading = ref(false);
const buyConfNumber = ref(0);
const refId = computed(() => route.query?.ref);

const { data: referrerData } = await useFetch<IUserModel['user']>(`${apiUrls.user}/${refId.value}`, {
  baseURL: apiUrl,
  watch: [refId],
  immediate: true,
  key: 'referrerData'
});

const closeReferral = () => {
  isShowReferralPopup.value = false;
  if (route.path.includes('referral')) {
    router.back();
  }
  router.replace({
    query: {
      ...route.query,
      ref: undefined
    }
  });
};

const checkReferrerExists = async (ref?: number) => {
  if (ref === undefined || isNaN(ref)) {
    return false;
  }
  const getUserExists = await PearlApiService.getUserById(apiUrl, ref as number);
  return getUserExists?.exists === true;
};

const registerHandler = async () => {
  if (!address.value) return;
  const getUserResp = await PearlApiService.getUser(apiUrl, address.value);
  if (getUserResp?.user?.id) {
    ElNotification.info({
      title: '',
      message: t('registerUserAlreadyJoined')
    });
    isShowReferralPopup.value = false;
    return;
  }

  try {
    if (address.value) {
      const receipt = await registerInReferral(referrerData.value?.address || ZeroAddress, () => {
        buyConfNumber.value += 1;
      });
      if (!receipt?.hash) return;

      saveHashToLocalStorage(t('notificationRegisterInReferral'), receipt.hash);
      store.updateVersion();

      await receipt?.wait(blockchain.minConfirmationsCount);

      const confirmations = await receipt.confirmations();
      if (confirmations >= blockchain.minConfirmationsCount && receipt?.hash) {
        modifyHistoryHashStatus(receipt.hash, 'Done');
        store.updateVersion();
      }

      const [userData, userSubscribersData] = await Promise.allSettled([
        PearlApiService.getUser(apiUrl, address.value),
        PearlApiService.getUserSubscribers(apiUrl, address.value)
      ]).then((results) => results.map((res) => (res.status === 'fulfilled' ? res.value : null)));

      if (userData) store.setUserData(userData as IUserModel);

      if (userSubscribersData) store.setUserSubscribersData(userSubscribersData as IUserSubscribers);
      joinLoading.value = false;
      isShowReferralPopup.value = false;
    }
  } catch (err) {
    showCancelPopup.value = true;
    if ((err as { code: string })?.code === ACTION_REJECTED) {
      errorMessage.value = 'referralInvitationCancelled';
      return;
    }
    joinLoading.value = false;
    errorMessage.value = stringifyError(err);
  }
};

const registerUserWithReferral = (ref?: number) => {
  // address or ref is undefined
  if (!isMobile && typeof ref !== 'number') {
    return;
  }
  joinLoading.value = true;

  // connect metamask first
  if (!address.value) {
    // if no address were found, trigger wallet logic;
    open();
    return;
  }
};

watch([address, joinLoading, isConnected], ([newAddress, newLoader, newIsConnected]) => {
  if (newAddress && newLoader && newIsConnected) registerHandler();
});

onMounted(() => {
  if (route.query?.ref) {
    checkReferrerExists(Number(route.query?.ref)).then((isExists) => {
      if (isExists) {
        isShowReferralPopup.value = true;
        return;
      }
      isShowReferralPopup.value = false;
      isShowReferrerError.value = true;
    });
  }
});
</script>

<style lang="scss" scoped>
.participate {
  margin-top: 20px;
}
</style>
